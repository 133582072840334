.mdtTableContainer {
  position: relative;
  overflow: visible;
  font-size: 15px;
  /* 12sp Roboto Medium, 54% black */
  /* optional animation of sort icons: add class 'animate-sort-icon' to mdt-header-row to activate animation */
  /* 64dp card header height */
  /* 56do for last row */
  /* column padding */
  /* 48dp row height */
  /* 13sp Roboto Regular, 87% black */
  /* border separation color */
  /* INTERACTION */
  /* default icon color */
  /* filter drop down */
  /* TH Select outline color & caret */
}
.mdtTableContainer .mdtTable {
  position: relative;
}
.mdtTableContainer .md-chips {
  font-size: 13px;
}
.mdtTableContainer .p-r {
  position: relative;
}
.mdtTableContainer .no-outline {
  outline: none;
}
.mdtTableContainer *,
.mdtTableContainer *:before,
.mdtTableContainer *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.mdtTableContainer table {
  width: 100%;
  display: table;
}
.mdtTableContainer table:focus {
  outline: none;
}
.mdtTableContainer td, .mdtTableContainer th {
  padding: 0;
  margin: 0;
}
.mdtTableContainer th {
  font-size: 12px;
  font-weight: 500;
  color: #757575;
  white-space: nowrap;
  /* prevent ink ripple bleeding */
  position: relative;
  /* no pointer cursor when disabled, could be not-allowed but i think that indication is to strong */
  /* remove the default blue outline in Chrome for consistent button-like behaviour */
  /* when hoverSortIcons on a non-sorted column*/
}
.mdtTableContainer th[disabled] {
  cursor: auto;
}
.mdtTableContainer th:focus, .mdtTableContainer th *:focus {
  outline: none;
}
.mdtTableContainer th .column-header-content {
  cursor: default;
}
.mdtTableContainer th .column-header-content.clickable {
  cursor: pointer;
}
.mdtTableContainer th .hoverSortIcons ng-md-icon {
  visibility: hidden;
  width: 16px;
  height: 16px;
  fill: #b3b3b3;
}
.mdtTableContainer th .hoverSortIcons ng-md-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mdtTableContainer th:hover .hoverSortIcons ng-md-icon {
  visibility: visible;
}
.mdtTableContainer th .sortedColumn {
  /* when hoverSortIcons on a sorted column*/
  /* sort icon rotated -90 degrees for descending sort */
}
.mdtTableContainer th .sortedColumn .hoverSortIcons ng-md-icon {
  display: none;
}
.mdtTableContainer th .sortedColumn ng-md-icon {
  /* specified 16px is a fix now cause angular materia generates a 24x24 icon even the passed value is 16  */
  width: 16px;
  height: 16px;
  fill: #212121;
  /* sort icon rotated 90 degrees for ascending sort (default) */
}
.mdtTableContainer th .sortedColumn ng-md-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mdtTableContainer th .sortedColumn.descending ng-md-icon > svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.mdtTableContainer tr.animate-sort-icon .sortedColumn ng-md-icon svg {
  -webkit-transition: 0.3s linear all;
  transition: 0.3s linear all;
}
.mdtTableContainer .mdt-header, .mdtTableContainer .mdt-header-alternate {
  height: 64px;
  padding-left: 24px;
  padding-right: 14px;
}
.mdtTableContainer .mdt-header md-button, .mdtTableContainer .mdt-header-alternate md-button {
  margin-left: 24px;
}
.mdtTableContainer .mdt-header ng-md-icon, .mdtTableContainer .mdt-header-alternate ng-md-icon {
  fill: #757575;
}
.mdtTableContainer .mdt-header-alternate {
  background-color: #e3edfd;
}
.mdtTableContainer .mdt-header-alternate .alternate-text {
  color: #0D47A1;
}
.mdtTableContainer .mdt-footer, .mdtTableContainer tr th {
  height: 56px;
  line-height: 56px;
}
.mdtTableContainer .mdt-footer .mdt-pagination, .mdtTableContainer tr th .mdt-pagination {
  font-size: 12px;
  color: #757575;
}
.mdtTableContainer .mdt-footer .mdt-pagination md-input-container, .mdtTableContainer tr th .mdt-pagination md-input-container {
  margin-top: 0px;
  margin-bottom: 0px;
}
.mdtTableContainer .mdt-footer {
  overflow: hidden;
}
.mdtTableContainer .checkboxCell {
  width: 18px;
  /*the next cell should not have just 24px padding */
}
.mdtTableContainer .checkboxCell md-checkbox {
  margin: 0;
  padding: 0;
}
.mdtTableContainer .checkboxCell + td, .mdtTableContainer .checkboxCell + th {
  padding-left: 24px;
}
.mdtTableContainer tr td {
  padding: 0;
  height: 48px;
  font-size: 13px;
  color: #212121;
}
.mdtTableContainer td:first-child, .mdtTableContainer th:first-child {
  padding: 0 0 0 24px;
}
.mdtTableContainer td:last-child, .mdtTableContainer th:last-child {
  padding-right: 24px;
}
.mdtTableContainer .column {
  padding-left: 56px;
}
.mdtTableContainer .leftAlignedColumn {
  text-align: left;
}
.mdtTableContainer .rightAlignedColumn {
  text-align: right;
}
.mdtTableContainer tr th {
  border-bottom: solid 1px #DDDDDD;
}
.mdtTableContainer tr td {
  border-bottom: solid 1px #DDDDDD;
}
.mdtTableContainer tr:hover td {
  background: #EEEEEE;
}
.mdtTableContainer .selectedRow td {
  background: #F5F5F5;
}
.mdtTableContainer ng-md-icon {
  fill: #757575;
}
.mdtTableContainer .md-inactive ng-md-icon {
  fill: rgba(0, 0, 0, 0.26);
}
.mdtTableContainer .md-virtual-repeat-container {
  min-height: 106px;
}
.mdtTableContainer .filter-select {
  border: 1px solid transparent;
  padding: 11px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-left: -10px;
}
.mdtTableContainer .filter-select:hover {
  border-color: #dedede;
}
.mdtTableContainer .filter-select:hover ng-md-icon {
  visibility: visible;
}
.mdtTableContainer .filter-select.is-active {
  color: #fff;
  background-color: #0096D6;
  border-color: #0096D6;
}
.mdtTableContainer .filter-select ng-md-icon {
  visibility: hidden;
}
.mdtTableContainer .filter-select.is-active ng-md-icon {
  visibility: visible;
  fill: rgba(255, 255, 255, 0.87);
  fill: #fff;
}
.mdtTableContainer md-progress-linear .md-container {
  height: 3px;
}
.mdtTableContainer md-progress-linear .md-bar {
  background: #2A87E3;
}
.mdtTableContainer .loading-indicator {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}
.mdtTableContainer .loading-is-active {
  visibility: visible;
}

/* filter drop down AND column selector */
.filter-dropdown, .mdt-column-selector {
  position: absolute;
  z-index: 1;
  width: 250px;
  text-align: initial;
}
.filter-dropdown a, .mdt-column-selector a {
  text-decoration: none;
}
.filter-dropdown .selectall_clearall, .mdt-column-selector .selectall_clearall {
  font-size: 12px;
}
.filter-dropdown .selectall_clearall span, .mdt-column-selector .selectall_clearall span {
  margin: 0 5px 0 5px;
}
.filter-dropdown .selectall_clearall .selected_items, .mdt-column-selector .selectall_clearall .selected_items {
  text-align: right;
  color: darkgray;
}
.filter-dropdown .selectall_clearall .disabled, .mdt-column-selector .selectall_clearall .disabled {
  color: #9E9E9E;
  cursor: default;
}
.filter-dropdown .hoverSortIcons ng-md-icon, .mdt-column-selector .hoverSortIcons ng-md-icon {
  visibility: hidden;
  width: 20px;
  height: 20px;
  fill: #b3b3b3;
}
.filter-dropdown .hoverSortIcons ng-md-icon svg, .mdt-column-selector .hoverSortIcons ng-md-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.filter-dropdown .hoverSortIcons ng-md-icon, .mdt-column-selector .hoverSortIcons ng-md-icon {
  visibility: visible;
}
.filter-dropdown .sortedColumn, .mdt-column-selector .sortedColumn {
  /* when hoverSortIcons on a sorted column*/
  /* sort icon rotated -90 degrees for descending sort */
}
.filter-dropdown .sortedColumn .hoverSortIcons ng-md-icon, .mdt-column-selector .sortedColumn .hoverSortIcons ng-md-icon {
  display: none;
}
.filter-dropdown .sortedColumn ng-md-icon, .mdt-column-selector .sortedColumn ng-md-icon {
  /* specified 16px is a fix now cause angular materia generates a 24x24 icon even the passed value is 16  */
  width: 20px;
  height: 20px;
  fill: #212121;
  /* sort icon rotated 90 degrees for ascending sort (default) */
}
.filter-dropdown .sortedColumn ng-md-icon svg, .mdt-column-selector .sortedColumn ng-md-icon svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.filter-dropdown .sortedColumn.descending ng-md-icon > svg, .mdt-column-selector .sortedColumn.descending ng-md-icon > svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.filter-dropdown .b-b, .mdt-column-selector .b-b {
  border-bottom: 1px solid #dedede;
}
.filter-dropdown .p-md, .mdt-column-selector .p-md {
  padding: 16px;
}
.filter-dropdown .p-l-md, .mdt-column-selector .p-l-md {
  padding-left: 16px;
}
.filter-dropdown .p-r-md, .mdt-column-selector .p-r-md {
  padding-right: 16px;
}
.filter-dropdown .p-t-md, .mdt-column-selector .p-t-md {
  padding-top: 16px;
}
.filter-dropdown .p-r-md, .mdt-column-selector .p-r-md {
  padding-right: 16px;
}
.filter-dropdown .p-smd, .mdt-column-selector .p-smd {
  padding: 12px;
}
.filter-dropdown .p-sm, .mdt-column-selector .p-sm {
  padding: 8px;
}
.filter-dropdown .p-l-sm, .mdt-column-selector .p-l-sm {
  padding-left: 8px;
}
.filter-dropdown .p-t-sm, .mdt-column-selector .p-t-sm {
  padding-top: 8px;
}
.filter-dropdown .p-b-sm, .mdt-column-selector .p-b-sm {
  padding-bottom: 8px;
}
.filter-dropdown .p-r-sm, .mdt-column-selector .p-r-sm {
  padding-right: 8px;
}
.filter-dropdown .p-b-n, .mdt-column-selector .p-b-n {
  padding-bottom: 0;
}
.filter-dropdown .md-chips, .mdt-column-selector .md-chips {
  font-size: 12px;
}
.filter-dropdown .filter__scroll, .mdt-column-selector .filter__scroll {
  max-height: 200px;
  overflow-y: auto;
  border-bottom: solid 1px #DDDDDD;
}

.mdt-column-selector {
  right: 0px;
  /* <-- this is a fix for positioning an element with the 'right' value using jquery. To make it work right:0px should be set in css before */
}
.mdt-column-selector .mdt-column-selector-title {
  color: #757575;
}
.mdt-column-selector .mdt-checbox-column-items span {
  width: 180px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
